<template>
  <div class="box">
    <div @click="go">选择支付方式</div>
    <a-radio-group v-model="value" @change="onChange" class="radio">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <a-radio :value="1" :style="radioStyle" class="zfMode"
          >微信支付</a-radio
        >
      </div>
      <div
        style="width: 89.0667vw; height: 0.1333vw; background: #e4e8eb"
      ></div>
      <div style="display: flex; justify-content: space-between">
        <!-- <img src="../assets/zfb.png" /> -->
        <a-radio :value="2" :style="radioStyle" class="zfMode"
          >支付宝支付</a-radio
        >
      </div>
    </a-radio-group>
    <div class="pay" v-if="value === 1">
      <div class="payLeft">
        <img src="../assets/wecat.png" alt="" />
        <div style="margin-left: 2vw">使用微信支付</div>
      </div>
      <div class="payNum">{{ money }}元</div>
    </div>
    <div class="pay" v-if="value === 2">
      <div class="payLeft">
        <img src="../assets/zfb.png" alt="" />
        <div style="margin-left: 2vw">使用支付宝支付</div>
      </div>
      <div class="payNum">{{ money }}元</div>
    </div>
    <div class="butt" @click="pay" :disabled="isDisabledSubmitBtn">支付</div>
  </div>
</template>

<script>
export default {
  props: ["phone","num"],
  data() {
    return {
      value: 1,
      isDisabledSubmitBtn: true, //按钮是否禁用
      radioStyle: {
        display: "block",
        height: "14.6667vw",
        lineHeight: "14.6667vw",
        textAlign: "left",
      },
      money: "",
	  num:"",
      object: "",
      visible: false,
    }
  },
  created() {
	  console.log('传过来的金额：'+this.num)
    // this.getmoney()
	this.money = this.num
  },
  methods: {
    go() {
      this.$router.push("/paySuccess")
    },
    onChange(e) {
      console.log("radio checked", e.target.value)
    },
    async getmoney() {
      const res = await this.request({
        method: "post",
        url: "https://api.iptzx.com/Crmapi/rzMoney",
        data: {},
      })
      // this.money = res.data.data
    },
    async pay() {
      this.isDisabledSubmitBtn = true //防止用户点击多次
      var ua = navigator.userAgent.toLowerCase()
      var isWeixin = ua.indexOf("micromessenger") != -1
      if (this.value === 1) {
        if (isWeixin) {
          const res = await this.request({
            method: "post",
            url: "http://ptpack.iptzx.com/api/crmapi/applyH5Pay",
            data: {
              phone: this.phone,
              type: 3,
            },
          })
		  console.log(res.data.data)
          this.object = res.data.data
          var vm = this
          // var timestamp = Math.round(vm.weChatParameter.timeStamp).toString()
          window.WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
              appId: vm.object.appId, //公众号名称，由商户传入
              timeStamp: vm.object.timestamp, //时间戳，自1970年以来的秒数
              nonceStr: vm.object.nonceStr, //随机串
              package: vm.object.package,
              signType: vm.object.signType, //微信签名方式：
              paySign: vm.object.paySign, //微信签名
            },
            function (res) {
              // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                this.$router.push("/paySuccess")
                //window.location.href = vm.BASE_URL + 'index.html#/depositResult'
              } else {
                alert("支付失败")
              }
            }
          )
        } else {
          const res = await this.request({
            method: "post",
            // url: "https://api.iptzx.com/crmapi/applyH5Pay",
			url: "https://ptpack.iptzx.com/api/crmapi/applyH5Pay",
            data: {
              phone: this.phone,
              type: 1,
            },
          })
          this.object = res.data.data
          console.log(this.object)
          window.location.href = this.object.mweb_url
        }
        // document.addEventListener("visibilitychange", function () {
        //   //需要的操作
        //   this.visible = true
        // })
      }
      // const phone = this.phone
      if (this.value === 2) {
        if (isWeixin) {
          alert("请不要在微信打开")
          return false
        } else {
          this.$router.push({
            path: "/payGateWay",
            query: { phone: this.phone },
          })
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  margin: 12vw 0 6.6667vw 5.3333vw;
  .radio {
    margin-top: 6.6667vw;
    height: 29.3333vw;
    width: 89.3333vw;
    border: 0.1333vw solid #e4e8eb;
    border-radius: 1.3333vw;
    img {
      width: 5.4667vw;
      height: 5.4667vw;
    }
    .zfMode {
      margin-left: 8.6667vw;
    }
  }
  .pay {
    margin: 7.3333vw 5.3333vw 7.3333vw 0;
    display: flex;
    justify-content: space-between;
    img {
      width: 5.4667vw;
      height: 5.4667vw;
    }
    .payLeft {
      display: flex;
    }
    .payNum {
      color: #0a49ff;
    }
  }
  .butt {
    width: 89.3333vw;
    height: 12.2667vw;
    background: #0a49ff;
    border-radius: 1.3333vw;
    text-align: center;
    line-height: 12.2667vw;
    font-size: 5.0667vw;
    color: #ffffff;
  }
}
</style>
