<template>
  <div class="home">
    <div style="margin: 0 auto; width: 1200px">
      <div class="navigation">
        <div style="display: flex; align-items: center">
          <img src="../assets/logo.png" style="width: 200px; height: 50px" />
          <div
            style="
              width: 1px;
              height: 20px;
              background: #bcbcbc;
              margin-left: 10px;
            "
          ></div>
          <div style="line-height: 50px; margin-left: 10px">
            专属企业官方新媒体平台
          </div>
        </div>
        <div class="rightNavigation">
          <div class="rightdetails">
            <img src="../assets/zhanghu.png" alt="" />
            <router-link to="/Home/Authentication">
				<div style="padding: 0 11px; color: #1a1a1a; font-size: 14px">
                <!-- 官媒注册 -->
                备案确权
				</div>
			</router-link>
          </div>
          <div class="rightdetails">
            <img src="../assets/shu.png" alt="" />
            <a href="https://www.iptzx.com"
              ><div
                style="
                  padding: 0 11px;
                  color: #1a1a1a;
                  width: 140px;
                  font-size: 14px;
                "
              >
                返回品推资讯
              </div></a
            >
          </div>
        </div>
      </div>
      <div style="text-align: center; margin: 0 auto; margin-top: 80px">
        <a-carousel autoplay>
          <div><img src="../assets/qqBanner.jpg" style="width: 1200px" /></div>
          <div><img src="../assets/banner1.png" style="width: 1200px" /></div>
          <div><img src="../assets/banner3.png" style="width: 1200px" /></div>
          <div><img src="../assets/banner2.png" style="width: 1200px" /></div>
          <div><img src="../assets/banner4.png" style="width: 1200px" /></div>
        </a-carousel>
      </div>
    </div>
    <div class="rightIcon">
      <div class="rexIcon">
        <div v-if="rex">
          <img
            src="../assets/rexian1.png"
            style="
              box-shadow: 0px 1px 12px 2px rgba(136, 136, 136, 0.25);
              border-radius: 10px;
              width: 180px;
              height: 54px;
            "
          />
        </div>
        <div
          style="margin-right: 10px; width: 132px; text-align: center"
          @mouseenter="changeRexIndex"
          @mouseleave="leaveRexIndex"
        >
          <img
            src="../assets/rexian.png"
            style="
              box-shadow: 0px 1px 12px 2px rgba(136, 136, 136, 0.25);
              width: 54px;
              height: 54px;
              border-radius: 50%;
            "
          />
        </div>
      </div>
      <div class="kefuIcon">
        <img
          src="../assets/saoma.png"
          style="
            margin-right: 10px;
            wdith: 126px;
            height: 163px;
            box-shadow: 0px 1px 12px 2px rgba(136, 136, 136, 0.25);
            border-radius: 10px;
          "
        />
      </div>
    </div>
	<help />
	<process />
    <bottomcentent />
    <equity />
    <anli />
	
    <business />
	
    <advantage />
    <case />
    <empowerment />
    <media />
    <homemiddle />
    <router-view />
  </div>
</template>

<script>
import Process from "../components/process.vue"
import Help from "../components/help.vue"
import Advantage from "../components/advantage.vue"
import Bottomcentent from "../components/bottomcentent.vue"
import Business from "../components/business.vue"
import Case from "../components/case.vue"
import Anli from "../components/anli.vue"
import Empowerment from "../components/empowerment.vue"
import homemiddle from "../components/homemiddle"
import Media from "../components/media.vue"
import Equity from "../components/equity.vue"
// @ is an alias to /src
export default {
  name: "Home",
  data() {
    return {
      rex: false,
    }
  },
  components: {
	Process,
	Help,
    homemiddle,
    Business,
    Case,
    Bottomcentent,
    Media,
    Advantage,
    Empowerment,
    Anli,
    Equity,
  },
  methods: {
    changeRexIndex() {
      this.rex = true
    },
    leaveRexIndex() {
      this.rex = false
    },
    gogog() {
      window._czc.push(["_trackEvent", "点击", "该事件执行的内容"])
    },
  },
}
</script>
<style lang="less" scoped>
.home {
  margin: 0 auto;
  background: #ffffff;
  .rightIcon {
    position: fixed;
    right: 0;
    top: 50%;
    .rexIcon {
      display: flex;
    }
    .kefuIcon {
      margin-top: 5px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .navigation {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    height: 80px;
    background: #ffffff;
    position: fixed;
    z-index: 9;
    top: 0;
    box-shadow: 0px 0px 1px 0px rgba(185, 185, 185, 0.31);
    .rightNavigation {
      display: flex;
      .rightdetails {
        margin-left: 11px;
        height: 14px;
        font-size: 14px;
        display: flex;
        line-height: 14px;
        margin-top: 33px;
      }
    }
  }
}
</style>
