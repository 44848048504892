<template>
	<div style="background: #f6f8f9; margin: 0 auto; height: 100%">
		<div v-if="show===0" style="background: #ffffff;">
			<!-- <img src="../assets/404.jpeg" style="display:block;margin: auto;max-width: 100%;"> -->
			<div style="text-align: center;font-size: 20px;margin-top: 300px;">该通道维护中，延迟开放</div>
		</div>
		<div v-if="show===1">
			<div style="width: 100%;background: #ffffff;box-shadow: 1px 3px 9px 0px rgba(211, 211, 211, 0.35);">
				<div class="content">
					<img src="../assets/logo.png" style="padding-top: 20px; width: 154px; height: 60px" />
					<a href="https://www.iptzx.com">
						<div class="title">返回品推资讯</div>
					</a>
				</div>
			</div>
			<div class="form">
				<div class="steps">
					<a-steps :current="current" size="small">
						<a-step v-for="item in steps" :key="item.title" :title="item.title" />
					</a-steps>
					<register v-if="index === 0" @form1="getMsgFormSon" />
					<means v-if="index === 1" @form2="backfa" @form3="enterpay" @number="getNum" :phone="phone" :password="password"
						:platform="platform" />
					<pay v-if="index === 2" :phone="phone" :num="num" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Means from "../components/means.vue"
	import Register from "../components/register.vue"
	import Pay from "../components/pay.vue"
	export default {
		name: "Authentication",
		components: {
			Register,
			Means,
			Pay,
		},
		data() {
			return {
				current: 0,
				index: 0,
				show: 1,
				steps: [{
						title: "基本信息",
						content: "1",
					},
					{
						title: "填写资料",
						content: "2",
					},
					{
						title: "支付费用",
						content: "3",
					},
				],
				MsgFormSon: "",
				phone: "",
				password: "",
				num:"",
				platform: "",
				pages: 2,
			}
		},
		methods: {
			getNum(data){
				console.log(data)
				this.num=data.data
			},
			getMsgFormSon(data) {
				console.log(data)
				this.phone = data.phone
				this.password = data.password
				this.platform = data.platform
				this.current = 1
				this.index = 1
			},
			backfa() {
				this.current--
				this.index--
			},
			enterpay(res) {
				console.log(res)
				this.current = 2
				this.index = 2
			},
			
		},
	}
</script>
<style lang="less" scoped>
	.content {
		margin: 0 auto;
		height: 80px;
		background: #ffffff;
		display: flex;
		justify-content: space-between;
		width: 1200px;

		.title {
			width: 100px;
			height: 14px;
			font-size: 14px;
			color: #1a1a1a;
			padding-top: 33px;
		}
	}

	.form {
		margin: 0 auto;
		text-align: center;
		background: #ffffff;
		box-shadow: 1px 3px 9px 0px rgba(211, 211, 211, 0.35);
		margin-top: 50px;
		width: 1200px;
		margin-bottom: 90px;

		.steps {
			width: 600px;
			padding-top: 60px;
			margin: 0 auto;
			text-align: center;
		}
	}
</style>
