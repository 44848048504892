<template>
  <div>
    <div class="middle">
      <div class="title">核验注册流程</div>
      <div style="display: flex">
        <div class="bottomBox">
          <img src="../assets/IMAGE60.png" style="width: 65px; height: 65px" />
          <div class="boxName">官媒昵称申请</div>
          <div class="box">一个手机号仅能申请一个企业官媒官媒昵称唯一</div>
        </div>
        <div
          style="
            width: 2px;
            height: 180px;
            background: #ffffff;
            opacity: 0.7;
            margin-top: 44px;
          "
        ></div>
        <div class="bottomBox">
          <img src="../assets/IMAGE61.png" style="width: 65px; height: 65px" />
          <div class="boxName">选择官媒类型</div>
          <div class="box">官媒昵称确认后选择官媒认证类型</div>
        </div>
        <div
          style="
            width: 2px;
            height: 180px;
            background: #ffffff;
            opacity: 0.7;
            margin-top: 44px;
          "
        ></div>
        <div class="bottomBox">
          <img src="../assets/IMAGE62.png" style="width: 65px; height: 65px" />
          <div class="boxName">提交资质审核</div>
          <div class="box">
            提交资质审核缴纳平台认证费用三个工作日内完成审核
          </div>
        </div>
        <div
          style="
            width: 2px;
            height: 180px;
            background: #ffffff;
            opacity: 0.7;
            margin-top: 44px;
          "
        ></div>
        <div class="bottomBox">
          <img src="../assets/IMAGE63.png" style="width: 65px; height: 65px" />
          <div class="boxName">享受官媒权益</div>
          <div class="box">
            审核成功后会收到平台开通短信我们将会为您开通官媒专属权益
          </div>
        </div>
      </div>
      <router-link to="/Home/Authentication"
        ><div class="buttom">官媒注册认证</div></router-link
      >
    </div>
    <div style="text-align: center; height: 128px; background: #f1f1f1">
      <a href="https://www.iptzx.com"
        ><div class="foot">品推资讯-企业官媒公众开放平台</div></a
      >
      <div style="font-size: 12px">
        渝ICP备19005330号 重庆企媒号网络科技有限公司
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.middle {
  width: 1200px;
  background-image: url("../assets/beijing6.png");
  background-size: 1200px 470px;
  height: 470px;
  margin: 0 auto;
  
  margin-top: 60px;
  
  .title {
    font-size: 44px;
    color: #ffffff;
    text-align: center;
    padding-top: 40px;
  }
  .bottomBox {
    width: 299px;
    text-align: center;
    margin-top: 44px;
    .box {
      width: 120px;
      margin: 0 auto;
      margin-top: 20px;
      color: #ffffff;
    }
    .boxName {
      font-size: 24px;
      color: #ffffff;
      margin-top: 18px;
    }
  }
  .buttom {
    width: 245px;
    height: 55px;
    background: #0a49ff;
    border-radius: 10px;
    margin: 30px auto;
    font-size: 20px;
    line-height: 55px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
  }
}
.foot {
  // height: 128px;
  color: #0a49ff;
  font-size: 14px;
  text-align: center;
  padding-top: 40px;
}
</style>
