<template>
  <div class="topmiddle">
    <div class="middletitle">
      <div>已认证官媒</div>
      <div class="buleIcon">CERTIFIED ENTERPRISE OFFICIAl MEDIA</div>
    </div>
    <a-carousel autoplay style="text-align: center; margin: 0 auto">
      <div>
        <div style="display: flex; justify-content: center; margin-top: 70px">
          <div class="imgIcon"><img src="../assets/image19.png" alt="" /></div>
          <div class="imgIcon" style="margin-left: 5px">
            <img src="../assets/image20.png" alt="" />
          </div>
          <div class="imgIcon" style="margin-left: 5px">
            <img src="../assets/image21.png" alt="" />
          </div>
          <div class="imgIcon" style="margin-left: 5px">
            <img src="../assets/image22.png" alt="" />
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 10px">
          <div class="imgIcon"><img src="../assets/image23.png" alt="" /></div>
          <div class="imgIcon" style="margin-left: 5px">
            <img src="../assets/image24.png" alt="" />
          </div>
          <div class="imgIcon" style="margin-left: 5px">
            <img src="../assets/image25.png" alt="" />
          </div>
          <div class="imgIcon" style="margin-left: 5px">
            <img src="../assets/image26.png" alt="" />
          </div>
        </div>
      </div>
      <div>
        <div style="display: flex; justify-content: center; margin-top: 70px">
          <div class="imgIcon"><img src="../assets/image8.png" alt="" /></div>
          <div class="imgIcon" style="margin-left: 5px">
            <img src="../assets/image9.png" alt="" />
          </div>
          <div class="imgIcon" style="margin-left: 5px">
            <img src="../assets/image10.png" alt="" />
          </div>
          <div class="imgIcon" style="margin-left: 5px">
            <img src="../assets/image11.png" alt="" />
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 10px">
          <div class="imgIcon"><img src="../assets/image12.png" alt="" /></div>
          <div class="imgIcon" style="margin-left: 5px">
            <img src="../assets/image13.png" alt="" />
          </div>
          <div class="imgIcon" style="margin-left: 5px">
            <img src="../assets/image14.png" alt="" />
          </div>
          <div class="imgIcon" style="margin-left: 5px">
            <img src="../assets/image15.png" alt="" />
          </div>
        </div>
      </div>
    </a-carousel>
    <!-- <router-link to="/Home/Authentication"
      ><div class="authentication">备案确权</div></router-link
    > -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        {
          img: require("../assets/image19.png"),
        },
        {
          img: require("../assets/image20.png"),
        },
        {
          img: require("../assets/image21.png"),
        },
        {
          img: require("../assets/image22.png"),
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.topmiddle {
  width: 1200px;
  margin-top: 70px;
  margin: 0 auto;
  .middletitle {
    text-align: center;
    height: 36px;
    font-size: 36px;
    margin-top: 80px;
    color: #333333;
    .buleIcon {
      font-size: 16px;
      border-radius: 6px;
      margin: 0 auto;
      margin-top: 15px;
    }
  }
  .imgIcon {
    img {
    }
  }
  .authentication {
    margin: 0 auto;
    margin-top: 40px;
    width: 245px;
    height: 55px;
    background: #0a49ff;
    color: #ffffff;
    line-height: 55px;
    font-weight: bold;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
  }
}
</style>
