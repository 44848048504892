<template>
  <a-form
    :form="form"
    @submit="handleSubmit"
    :label-col="{ span: 5 }"
    :wrapper-col="{ span: 15 }"
    labelAlign="left"
  >
    <a-form-item
      v-bind="formItemLayout"
      label="手机号码"
      style="margin-top: 80px"
    >
      <a-row :gutter="15">
        <a-col :span="17">
          <a-input
            v-decorator="[
              'phone',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入正确的手机号码',
                    min: 11,
                  },
                ],
              },
            ]"
            placeholder="将用作企业官媒平台登录账户"
          />
        </a-col>
        <a-col :span="2" v-if="showtime === null">
          <a-button @click="send">获取验证码</a-button>
        </a-col>
        <a-col :span="2" v-else>
          <a-button> {{ showtime }}</a-button>
        </a-col>
      </a-row>
      <div style="font-size: 14px; color: #737373; width: 520px">
        <!-- 请填写未在企业官媒平台注册的手机号码，每个手机号只能申请一个企业官媒平台 -->
      </div>
    </a-form-item>
    <a-form-item v-bind="formItemLayout" label="验证码">
      <a-row :gutter="8">
        <a-col :span="25">
          <a-input
            v-decorator="[
              'captcha',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入6位验证码',
                    min: 6,
                  },
                ],
              },
            ]"
            placeholder="请输入验证码"
          />
        </a-col>
      </a-row>
    </a-form-item>
    <!-- <a-form-item v-bind="formItemLayout" label="密码" has-feedback>
      <a-input
        v-decorator="[
          'password',
          {
            rules: [
              {
                required: true,
                message: '请输入您的密码',
                min: 6,
                max: 12,
              },
              {
                validator: validateToNextPassword,
              },
            ],
          },
        ]"
        placeholder="含数字，字母，区分大小写，6-12位"
        type="password"
      />
    </a-form-item> -->
    <!-- <a-form-item v-bind="formItemLayout" label="确认密码" has-feedback>
      <a-input
        v-decorator="[
          'confirm',
          {
            rules: [
              {
                required: true,
                message: '请输入您的密码',
                min: 6,
                max: 12,
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          },
        ]"
        placeholder="请再次输入密码"
        type="password"
        @blur="handleConfirmBlur"
      />
    </a-form-item> -->
    <a-form-item v-bind="tailFormItemLayout">
      <a-checkbox
        v-decorator="[
          'agreement',
          {
            rules: [
              {
                required: true,
                message: '请勾选且同意《企业官媒用户协议》',
              },
            ],
          },
        ]"
      >
        我同意并遵守
        <a
          href="https://view.officeapps.live.com/op/view.aspx?src=https://video-1300439097.cos.ap-chongqing.myqcloud.com/school/企业官媒平台服务协议.docx"
        >
          《企业官媒平台服务协议》
        </a>
      </a-checkbox>
    </a-form-item>
    <a-form-item v-bind="tailFormItemLayout" style="padding-bottom: 40px">
      <a-button type="primary" html-type="submit" class="button">
        申请注册
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      phone: "",
      timeCounter: null,
      showtime: null,
      confirmDirty: false,
      autoCompleteResult: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 5,
          },
          sm: {
            span: 16,
            offset: 4,
          },
        },
      },
      values: "",
      msg: "",
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" })
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault()
      const form = this.form
      const phone = form.getFieldValue("phone")
      const code = form.getFieldValue("captcha")
      // console.log(phone)
      const res = await this.request({
        method: "post",
        url: "https://api.iptzx.com/Other/checkSendCode",
        data: {
          phone,
          code,
        },
      })
      this.msg = res.data.data
      if (res.data.code === 200) {
        this.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            this.$emit("form1", values)
            this.$notification.open({
              message: "申请认证通过！",
              description: "申请认证通过，请稍等！",
              onClick: () => {
                console.log("Notification Clicked!")
              },
            })
          }
        })
      } else {
        alert(this.msg)
      }
    },
    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue("password")) {
        callback("您的密码不相同!")
      } else {
        callback()
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true })
      }
      callback()
    },
    handleWebsiteChange(value) {
      let autoCompleteResult
      if (!value) {
        autoCompleteResult = []
      } else {
        autoCompleteResult = [".com", ".org", ".net"].map(
          (domain) => `${value}${domain}`
        )
      }
      this.autoCompleteResult = autoCompleteResult
    },
    // 倒计时 60秒 不需要很精准
    countDown(times) {
      const self = this
      // 时间间隔 1秒
      const interval = 1000
      let count = 0
      self.timeCounter = setTimeout(countDownStart, interval)
      function countDownStart() {
        if (self.timeCounter == null) {
          return false
        }
        count++
        self.countDownText(times - count + 1)
        if (count > times) {
          clearTimeout(self.timeCounter)
          self.showtime = null
        } else {
          self.timeCounter = setTimeout(countDownStart, interval)
        }
      }
    },
    //倒计时验证码
    countDownText(s) {
      this.showtime = `${s}s后重新获取`
    },
    //发送验证码
    async send() {
      const form = this.form
      const phone = form.getFieldValue("phone")
      if (form.getFieldValue("phone") === undefined) {
        alert("请输入您的手机号码!")
      } else {
        const res = await this.request({
          method: "post",
          url: "https://api.iptzx.com/Other/sendMsg",
          data: {
            phone,
          },
        })
        if (res.data.code === 500) {
			// console.log(res)
          alert(res.data.data)
          return
        }
        this.countDown(60)
        this.$notification.open({
          message: "短信发送成功！",
          description: "短信发送成功，请稍等！",
          onClick: () => {
            console.log("Notification Clicked!")
          },
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.button {
  color: #1a1a1a;
  background: #ffffff;
  border: 1px solid #e4e8eb;
  border-radius: 4px;
}
</style>
