<template>
  <div>
	  <div v-if="show==0">
		  <div style="text-align: center;font-size: 20px;margin-top: 300px;">该通道维护中，延迟开放</div>
	  </div>
    <div v-if="show==1">
		<div class="top">
		  <img src="../assets/logo.png" style="width: 24vw; height: 6vw" />
		</div>
		<div class="steps">
		  <div
		    :class="item.show === true ? 'stepsIndex' : 'stepsTitle'"
		    v-for="(item, index) in stepsList"
		    :key="index"
		  >
		    {{ item.title }}
		  </div>
		</div>
		<mobile-register
		  v-if="index === 0"
		  @form1="getMsgFormSon"
		></mobile-register>
		<mobile-means
		  v-if="index === 1"
		  @form2="backfa"
		  @form3="enterpay"
		  @number="getNum"
		  :phone="phone"
		  :password="password"
		  :platform="platform"
		></mobile-means>
		<mobilepay v-if="index === 2" :phone="phone" :num="num"></mobilepay>
	</div>
  </div>
</template>

<script>
import MobileMeans from "../components/mobileMeans.vue"
import Mobilepay from "../components/mobilepay.vue"
import mobileRegister from "../components/mobileRegister.vue"
export default {
  components: { mobileRegister, MobileMeans, Mobilepay },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
      stepsList: [
        {
          title: "①基本信息",
          show: true,
        },
        {
          title: "②填写资料",
          show: false,
        },
        {
          title: "③支付费用",
          show: false,
        }
      ],
      index: 0,
      password: "",
	  num: "",
	  show:"1"
    }
  },
  methods: {
	getNum(data){
		console.log(data)
	  	this.num=data.data
	},
    getMsgFormSon(data) {
      console.log(data)
      this.phone = data.phone
      this.password = data.password
      this.platform = data.platform
      this.stepsList[0].show = false
      this.stepsList[1].show = true
      this.index = 1
    },
    backfa() {
      this.stepsList[0].show = true
      this.stepsList[1].show = false
      this.index = 0
    },
    enterpay() {
      this.stepsList[1].show = false
      this.stepsList[2].show = true
      this.index = 2
    },
  },
}
</script>

<style lang="less" scoped>
.top {
  width: 100%;
  height: 10.6667vw;
  box-shadow: 0px 0.4vw 1.7333vw 0.1333vw rgba(189, 189, 189, 0.32);
  padding: 2.6667vw 0 0 5.3333vw;
}
.steps {
  display: flex;
  margin-top: 8.6667vw;
  justify-content: center;
  align-items: center;
  height: 3.3333vw;
  .stepsTitle {
    margin-left: 2.6667vw;
  }
  .stepsLine {
    width: 4.5333vw;
    height: 0.4vw;
    background: #f1f1f1;
    margin-left: 1.3333vw;
  }
}
.ant-form {
  margin: 10.6667vw 5.3333vw 8vw 5.3333vw;
}
.stepsIndex {
  color: #0a49ff;
}
</style>
