<template>
  <div class="topmiddle">
    <div class="middletitle">
      <div>经典盈利商业模式案例</div>
      <div class="buleIcon">Classic Profitable Business Model Case</div>
    </div>
    <div style="margin-top: 87px; display: flex">
      <div>
        <div
          :class="item.show === false ? 'leftBox' : 'leftPitch'"
          v-for="(item, index) in List"
          :key="item.index"
          @mouseenter="changeHoverIndex(index)"
          @mouseleave="leaveHoverIndex(index)"
        >
          <img :src="item.img" style="width: 44px; height: 44px" />
          <div style="margin-left: 20px">
            <div class="title">
              {{ item.title }}
              <img
                src="../assets/gm-icon.svg"
                style="width: 39px; height: 33px"
              />
              <img
                src="../assets/renzheng1.svg"
                style="width: 23px; height: 23px"
              />
            </div>
            <div class="concent">{{ item.concent }}</div>
          </div>
        </div>
      </div>
      <div
        class="rightBox"
        v-for="(item, index) in rightList"
        :key="index"
        v-show="show === index"
      >
        <div class="rightConcent">
          {{ item.rightconcent }}
        </div>
        <div style="display: flex; margin-top: 56px">
          <div class="rightBottomCent">
            <div class="rightBottomCentTop">
              {{ item.Franchise }}<span style="font-size: 16px">元</span>
            </div>
            <div class="rightBottomCentBot">加盟费</div>
          </div>
          <div class="rightBottomCent">
            <div class="rightBottomCentTop">{{ item.proportion }}</div>
            <div class="rightBottomCentBot">分佣比例</div>
          </div>
          <div class="rightBottomCent">
            <div class="rightBottomCentTop">
              {{ item.Franchisee }}<span style="font-size: 16px">家</span>
            </div>
            <div class="rightBottomCentBot">加盟商</div>
          </div>
          <div class="rightBottomCent">
            <div class="rightBottomCentTop">
              {{ item.profit }}<span style="font-size: 16px">元+</span>
            </div>
            <div class="rightBottomCentBot">营收</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      List: [
        {
          img: require("../assets/anli4.png"),
          title: "企业官媒平台:安邦客家政",
          concent: "利用加盟商管理系统实现盈利",
          show: true,
        },
        {
          img: require("../assets/anli5.png"),
          title: "企业官媒平台:南京盐水鸭",
          concent: "利用加盟商管理系统实现盈利",
          show: false,
        },
        {
          img: require("../assets/anli6.png"),
          title: "企业官媒平台:养老养生",
          concent: "利用加盟商管理系统实现盈利",
          show: false,
        },
      ],
      rightList: [
        {
          rightconcent:
            "安邦客家政是深圳的一家做家政平台的企业,注册认证企业官媒后,利用免费的加盟商系统他通过我们专业的运营团队免费为他的客户策划了一个营销解决方案,充分利用了加盟商系统。他设置的加盟商金额为365元,短短不到一个月时间,他就发展了300位用户成为了他的加盟商,同时加盟商推荐用户成为加盟商均可享受8折优惠家政服务,推荐者均可获得高达70%的加盟费分佣,一个月时间内实现收益109500元+。",
          Franchise: "365",
          proportion: "3:7",
          Franchisee: "300",
          profit: "109500",
        },
        {
          rightconcent:
            "张季注册认证南京盐水鸭企业官媒后,充分发挥加盟商系统的优势,我们的策划团队免费为他特别定制了一个营销策划方案,他设置的加盟费为498元整,置分佣比例30%-70%,加盟商推荐加盟商还可以享受70%高额加盟费分佣奖励,被推荐者同样仅限享受7折优惠,3个月时间发展加盟商791家,直接实现收益393918元+。",
          Franchise: "498",
          proportion: "3:7",
          Franchisee: "791",
          profit: "393918",
        },
        {
          rightconcent:
            "于红梅注册认证养老养生企业官媒平台后，我们专业的运营团队发挥微销通企业分销系统的强大管理功能优势，我们的策划团队为她策划的方案是:招募合伙人。她招募了30位推广合伙人,让合伙人的微信号作为子账号绑定了自己的主账号,通过30位合伙人的行业资源、渠道,在养老养生行业推广发展加盟商,凡关注养老养生企业官媒,成为加盟商的商家/企业，均可免费享受一年的推广及广告投放服务,他设置的加盟商费为2500/年,分佣比例为5:5,3个月时间,平均每个合伙人发展9-15家不等家加盟商,总体三个月时间发展了336家,实现总收益840000元整,因为是设置的五五分佣,养老养生自己所获得的收益420000元整。",
          Franchise: "365",
          proportion: "3:7",
          Franchisee: "300",
          profit: "420000",
        },
      ],
      show: 0,
    }
  },
  methods: {
    changeHoverIndex(index) {
      this.show = index
      this.List[index].show = !this.List[index].show
      this.List[0].show = false
    },
    leaveHoverIndex(index) {
      this.List[index].show = !this.List[index].show
      this.List[0].show = true
      this.show = 0
    },
  },
}
</script>

<style lang="less" scoped>
.topmiddle {
  width: 1200px;
  margin-top: 70px;
  margin: 0 auto;
  .middletitle {
    text-align: center;
    height: 36px;
    font-size: 36px;
    margin-top: 80px;
    color: #333333;
    .buleIcon {
      color: #4a4a4a;
      font-size: 16px;
      margin: 0 auto;
      margin-top: 15px;
    }
  }
  .leftBox {
    width: 512px;
    height: 128px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    padding: 40px 35px;
    align-items: center;
    text-align: left;
    margin-top: 8px;
    .title {
      font-size: 24px;
      color: #333333;
    }
    .concent {
      font-size: 16px;
      color: #4a4a4a;
    }
  }
  .leftPitch {
    box-shadow: 0px 2px 10px 1px rgba(185, 185, 185, 0.29);
    border-radius: 10px;
    width: 512px;
    height: 128px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    padding: 40px 35px;
    align-items: center;
    text-align: left;
    margin-top: 8px;
    .title {
      font-size: 24px;
      color: #333333;
    }
    .concent {
      font-size: 16px;
      color: #4a4a4a;
    }
  }
  .rightBox {
    width: 590px;
    height: 400px;
    background-image: url("../assets/beijin1.png");
    // opacity: 0.6;
    border-radius: 10px;
    margin-left: 88px;
    padding: 67px 64px;
    .rightConcent {
      color: #ffffff;
      text-align: left;
      font-size: 15px;
    }
    .rightBottomCent {
      text-align: left;
      // margin-right: 15px;
      .rightBottomCentTop {
        width: 120px;
        font-weight: bold;
        font-size: 22px;
        color: #ffffff;
      }
      .rightBottomCentBot {
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
}
</style>
